import React from 'react';
import PropTypes from 'prop-types/index';
import {SliderWrapperStyled, SliderStyled, ArrowStyled, IconStyled} from './styles';

function NextArrow(props) {
	const {className, onClick} = props;
	const disabled = className.includes('slick-disabled');
	return (
		<ArrowStyled onClick={onClick} disabled={disabled} className="slick-arrow slick-arrow-right">
			<IconStyled name="arrow_right" />
		</ArrowStyled>
	);
}

function PrevArrow(props) {
	const {className, onClick} = props;
	const disabled = className.includes('slick-disabled');
	return (
		<ArrowStyled onClick={onClick} disabled={disabled} className="slick-arrow slick-arrow-left">
			<IconStyled name="arrow_left" />
		</ArrowStyled>
	);
}

class Slider extends React.Component {
	settings = {
		// eslint-disable-next-line react/destructuring-assignment
		infinite: this.props.infinite,
		speed: 500,
		// eslint-disable-next-line react/destructuring-assignment
		slidesToShow: this.props.maxShow,
		slidesToScroll: 1,
		// eslint-disable-next-line react/destructuring-assignment
		initialSlide: this.props.initialSlide,
		nextArrow: <NextArrow onClick={this.handleClickLeft} />,
		prevArrow: <PrevArrow onClick={this.handleClickRight} />,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					// eslint-disable-next-line react/destructuring-assignment
					slidesToShow: this.props.maxShow < 2 ? this.props.maxShow : 2,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					// eslint-disable-next-line react/destructuring-assignment
					slidesToShow: this.props.maxShow < 3 ? this.props.maxShow : 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					// eslint-disable-next-line react/destructuring-assignment
					slidesToShow: this.props.maxShow < 2 ? this.props.maxShow : 2,
				},
			},
			{
				breakpoint: 580,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	handleClickLeft = () => {
		this.slider.slickPrev();
	};

	handleClickRight = () => {
		this.slider.slickNext();
	};

	render() {
		const {children} = this.props;

		return (
			<SliderWrapperStyled className="slider">
				<SliderStyled ref={(slider) => (this.slider = slider)} {...this.settings}>
					{children}
				</SliderStyled>
			</SliderWrapperStyled>
		);
	}
}

Slider.propTypes = {
	children: PropTypes.arrayOf(PropTypes.object),
	maxShow: PropTypes.number,
	initialSlide: PropTypes.number,
	infinite: PropTypes.bool,
};

Slider.defaultProps = {
	children: null,
	maxShow: 3,
	initialSlide: 0,
	infinite: false,
};

/** @component */
export default Slider;
