import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../assets/img/logos/logo_cleo.svg';
import {CleoLogoStyled, LinkStyled} from './styles';

const LogoCleo = props => {
	const {className} = props;
	return (
		<LinkStyled to="https://www.cleo-app.de/" className={className}>
			<CleoLogoStyled src={Logo} />
		</LinkStyled>
	);
};

LogoCleo.propTypes = {
	className: PropTypes.string
};

LogoCleo.defaultProps = {
	className: 'ap-logo-cleo'
};

/** @component */
export default LogoCleo;
