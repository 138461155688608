import React from 'react';
import {StaticQuery, graphql} from 'gatsby';

import MSSliderContent from './MSSliderContent';

const MSSlider = (props) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressPage(
					filter: {wordpress_parent: {eq: 57}, slug: {ne: "ueber-anna"}, title: {ne: "News_und_Specials"}}
					sort: {fields: menu_order}
				) {
					edges {
						node {
							title
							path
							featured_media {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => <MSSliderContent {...props} data={data} />}
	/>
);

export default MSSlider;
