import styled from 'styled-components';
import Slider from 'react-slick';

import {withUnit, gradientButton, media} from '../../../styles/utils';
import {sizes, colors} from '../../../styles/variables';
import {Icon} from '../../Elements/Icon/Icon';

export const SliderWrapperStyled = styled.div`
	display: block;
	padding: 0 ${withUnit(sizes.distance.base / 2)};
	padding-bottom: ${withUnit(sizes.distance.base * 1.5)};
`;

export const IconStyled = styled(Icon)`
	transition: transform 0.6s ease;

	svg {
		fill: ${colors.white};
		width: 1.1em;
		height: 1.1em;
	}
`;

export const ArrowStyled = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	width: 60px;
	height: 60px;
	margin: 0.5em;
	border-radius: 50%;
	z-index: 1;

	will-change: background-color, background-position, transform, font-size, box-shadow;
	transition: all 0.3s ease-out;

	${({disabled}) =>
		disabled
			? `
				background: ${colors.silver};
			`
			: `
				${gradientButton()};
				&:not([disabled]):hover {
					transform: translateY(-3px);
					box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
				}
	`}
`;

export const SliderStyled = styled(Slider)`
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base * 1.5)};
	margin-left: -${withUnit(sizes.distance.base)};
	margin-right: -${withUnit(sizes.distance.base)};

	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	${media.sm`
		
		margin-top: 0;
		margin-bottom: 0;
		margin-left: -${withUnit(sizes.distance.base)};
		margin-right: -${withUnit(sizes.distance.base)};
	`};

	.slick-list {
		position: relative;

		display: block;
		overflow: hidden;

		margin: 0;
		padding: 0 0 ${withUnit(sizes.distance.base / 2)} 0;
	}
	.slick-list:focus {
		outline: none;
	}
	.slick-list.dragging {
		cursor: pointer;
	}

	&.slick-slider .slick-track,
	&.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;

		display: block;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;

		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;
		box-sizing: border-box;

		padding: 0;

		${media.sm`
			padding: ${withUnit(sizes.distance.base / 4)}; 
  		`}

		${media.lg`
			padding: ${withUnit(sizes.distance.base / 2)};
  		`}

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	&.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow {
		position: absolute;
		bottom: -70px;
		${media.sm`
			bottom: -60px;
		`};
	}
	.slick-arrow-left {
		right: 50%;
		${media.sm`
			right: 80px;
			`};
	}
	.slick-arrow-right {
		right: 30%;
		${media.sm`
			right: 0;
			`};
	}
`;
