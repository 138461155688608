import React from 'react';
import Logo from '../../../assets/img/logos/logo_ms-community.svg';
import {CommunityLogoStyled, LinkStyled} from './styles';

const LogoCommunity = () => {
	return (
		<LinkStyled to="/ms-community/" className="ap-logo-community">
			<CommunityLogoStyled src={Logo} />
		</LinkStyled>
	);
};

/** @component */
export default LogoCommunity;
