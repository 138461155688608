import React from 'react';
import PropTypes from 'prop-types';
import {IntroStyled} from './styles';

const Intro = props => {
	const {className, maxWidth, content} = props;
	return <IntroStyled className={className} maxWidth={maxWidth} dangerouslySetInnerHTML={{__html: content}} />;
};

Intro.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	maxWidth: PropTypes.number,
	content: PropTypes.string.isRequired
};

Intro.defaultProps = {
	className: 'ap-text',
	maxWidth: null
};

/** @component */
export default Intro;
